
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  Ref,
  onUnmounted,
  onActivated,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Toast } from "vant";
import * as service from "@/api/inquiry";
import { getUserCity } from "@/utils/StorageHelper.ts";
import { Response } from "../../types/check";
import { setChannelId } from "@/utils/StorageHelper.ts";
import intentionCarModel from "./components/intention_car_model.vue";
export default defineComponent({
  name: "InquriyContent",
  components: {
    intentionCarModel,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    // 动态设置keepalive
    store.commit("SetKeep");
    const param = reactive({
      avatar: "",
      nickName: "",
      channelId: "",
      cityCode: "",
      cityName: "",
      gender: 0,
      mobile: "",
      purchaseBudgets: [],
      purchaseTime: "",
      intendedModel: "",
      intendedModelName: "",
      indicativePrice: 0,
      brandAvatar: "",
    });
    const validCode = ref(""); //验证码
    const hasVerify = ref(false); //是否校验过
    const verifySuccess = ref(false); //验证码验证通过
    const hasSendCode = ref(false); //是否发送过验证码
    const checkSuccess = ref(false); //验证成功
    const times = ref(60); //倒计时
    const timer = ref(0);
    const purchaseTimeArr = ref([
      {
        code: "7",
        name: "7天内",
        check: false,
      },
      {
        code: "15",
        name: "15天内",
        check: false,
      },
      {
        code: "30",
        name: "30天内",
        check: false,
      },
      {
        code: "90",
        name: "1-3个月",
        check: false,
      },
      {
        code: "0",
        name: "3个月以上",
        check: false,
      },
    ]);
    const purchaseBudgetsArr = reactive([
      {
        code: "0-10",
        name: "0-10万",
        check: false,
      },
      {
        code: "10-15",
        name: "10-15万",
        check: false,
      },
      {
        code: "15-25",
        name: "15-25万",
        check: false,
      },
      {
        code: "25-30",
        name: "25-30万",
        check: false,
      },
      {
        code: "30-50",
        name: "30-50万",
        check: false,
      },
      {
        code: "50-",
        name: "50万以上",
        check: false,
      },
    ]);
    const genderArr = reactive([
      {
        code: 1,
        name: "男",
      },
      {
        code: 2,
        name: "女",
      },
    ]);
    const hasIntentionCar = ref(false); //是否有意向车型
    // 选择性别
    function checkGender(value: number): void {
      param.gender = value;
    }
    function mobileInput() {
      validCode.value = "";
      hasVerify.value = false;
      verifySuccess.value = false;
    }
    // 发送短信验证码之前
    function beforeSendCode(): void {
      if (!param.mobile) {
        Toast("输入手机号");
        return;
      }
      const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phoneReg.test(param.mobile)) {
        Toast("输入正确的手机号");
        return;
      }
      validCode.value = "";
      hasVerify.value = false;
      verifySuccess.value = false;
      this.getVerifyCode();
    }
    // 发送短信验证码
    async function getVerifyCode() {
      clearInterval(timer.value);
      times.value = 60;
      // @ts-ignore
      window.sensorsDataAnalytic201505.track("inquiry_h5_tap_event", {
        type: "验证码发送",
      });
      const mobile = param.mobile;
      try {
         // @ts-ignore
        const { data,code,msg } = await service.sendcode({ mobile });
        if (code == 200) {
          Toast("验证码已发送");
        }else{
          Toast(msg);
        }
        hasSendCode.value = true;
        timdDown();
      } catch (error) {
        Toast(error.message);
      }
    }
    // 倒计时
    function down() {
      if (times.value == 0) {
        hasSendCode.value = false;
        times.value = 60;
      }
      times.value--;
    }
    // 倒计时
    function timdDown() {
      timer.value = setInterval(down, 1000);
    }
    //校验验证码
    async function checkVerifydCode() {
      if (!param.mobile) {
        Toast("请输入手机号");
        return;
      }
      const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phoneReg.test(param.mobile)) {
        Toast("输入正确的手机号");
        return;
      }
      if (!validCode.value) {
        Toast("请输入验证码");
        return;
      }
      try {
        const params = { mobile: param.mobile, validCode: validCode.value };
        const res: Response<any> = await service.verifycode(params);
        if (res.code !== 200) throw new Error(res.msg);
        hasVerify.value = true;
        verifySuccess.value = true;
        if (res.data) {
          checkSuccess.value = true;
        } else {
          checkSuccess.value = false;
        }
      } catch (error) {
        hasVerify.value = true;
        verifySuccess.value = false;
        checkSuccess.value = false;
        Toast(error.message);
      }
    }
    // 选出城市
    function selectCity() {
      // @ts-ignore
      window.sensorsDataAnalytic201505.track("inquiry_h5_tap_event", {
        type: "上牌地点",
      });
      router.push({ path: "/city-select" });
    }
    // 选择购车时间
    function timeButtonClick(index: number) {
      const arr: any = purchaseTimeArr.value;
      for (const i in arr) {
        if (String(index) == i) {
          arr[i].check = true;
          param.purchaseTime = arr[i].code;
        } else {
          arr[i].check = false;
        }
      }
      purchaseTimeArr.value = [...arr];
    }
    // 选择购车预算
    function budgetButtonClick(index: number): void {
      purchaseBudgetsArr[index].check = !purchaseBudgetsArr[index].check;
      const arr: any = [];
      purchaseBudgetsArr.forEach((item) => {
        item.check ? arr.push(item.code) : "";
      });
      param.purchaseBudgets = arr;
    }
    //数据校验
    function verfiyFull() {
      //控制必填 上牌地，手机号，预算，购车时间
      if (!param.cityName) {
        Toast("请选择上牌地");
        return;
      }
      if (!param.mobile) {
        Toast("请输入手机号");
        return;
      }
      const phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phoneReg.test(param.mobile)) {
        Toast("输入正确的手机号");
        return;
      }
      if (!param.purchaseTime) {
        Toast("请选择购车时间");
        return;
      }
      //如果有选择购车意向，车型必传，否则预算必传
      if (!hasIntentionCar.value) {
        const carmodelData = store.state.getCarModel;
        if (!carmodelData.id) {
          Toast("请选择意向车型");
          return;
        }
        param.intendedModel = carmodelData.id;
        param.intendedModelName = carmodelData.name;
        param.indicativePrice = carmodelData.indicativePrice;
        param.brandAvatar = carmodelData.brandAvatar;
      } else {
        if (param.purchaseBudgets.length === 0) {
          Toast("请选择购车预算");
          return;
        }
      }
      if (!verifySuccess.value) {
        Toast("请输入正确的验证码");
        return;
      }
      // todo :询价次数限制
      // 通过手机号判断一天最多询10条,超出后提示
      this.submitInquiry();
    }
    function submitInquiry() {
      console.log("执行提交");
      if (!hasIntentionCar.value) {
        param.purchaseBudgets = [];
      }
      //选择的车型和预算二选一
      if (param.purchaseBudgets.length != 0 && hasIntentionCar.value) {
        param.indicativePrice = 0;
        param.intendedModel = "";
        param.intendedModelName = "";
        param.brandAvatar = "";
      }
      console.log("最后的参数", param);
      // @ts-ignore
      window.sensorsDataAnalytic201505.track("inquiry_h5_tap_event", {
        type: "秒询底价",
      });
      store.commit("setQuoteParam", param);
      router.replace({ path: "/in_quotation" });
    }
    function changeIntention(e: any) {
      hasIntentionCar.value = e;
    }
    onMounted(() => {
      const channelId = route.query.channelId;
      param.channelId = channelId as string;
      setChannelId(channelId);
      const city: any = getUserCity();
      if (city) {
        const cityObj = JSON.parse(city);
        param.cityCode = cityObj.cityCode;
        param.cityName = cityObj.cityName;
      }
    });
    onUnmounted(() => {
      //清除定时器
      clearInterval(timer.value);
    });
    onActivated(() => {
      const channelId = route.query.channelId;
      param.channelId = channelId as string;
      setChannelId(channelId);
      const city: any = getUserCity();
      if (city) {
        const cityObj = JSON.parse(city);
        param.cityCode = cityObj.cityCode;
        param.cityName = cityObj.cityName;
        console.log("cityCode", cityObj.cityCode);
      }
    });
    return {
      ...toRefs(param),
      validCode, //验证码
      hasVerify,
      verifySuccess, //验证码验证通过
      hasSendCode,
      checkSuccess,
      times,
      purchaseTimeArr,
      purchaseBudgetsArr,
      genderArr,
      checkGender,
      selectCity,
      mobileInput,
      beforeSendCode,
      verfiyFull,
      getVerifyCode,
      checkVerifydCode,
      down,
      timdDown,
      timer,
      timeButtonClick,
      budgetButtonClick,
      submitInquiry,
      hasIntentionCar,
      changeIntention,
    };
  },
});
