
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  Ref,
  onUnmounted,
  onActivated,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  props: {},
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const hasIntentionCar = ref(false);
    const carModel = ref({});
    //选择车型
    function chooseModal() {
      router.push({ path: "/car_model_picker" });
    }
    function changeIntention() {
      hasIntentionCar.value = !hasIntentionCar.value;
      context.emit("changeIntention", hasIntentionCar.value);
      store.commit("setHasIntentionCar", hasIntentionCar.value);
    }
    onMounted(() => {
      //选择的车型
      const carmodelData = store.state.getCarModel;
      carModel.value = carmodelData;
    });
    onActivated(() => {
      const carmodelData = store.state.getCarModel;
      carModel.value = carmodelData;
      console.log("选中的车型是这个", carModel.value);
    });
    return {
      hasIntentionCar,
      carModel,
      chooseModal,
      changeIntention,
    };
  },
});
